<template>
  <div>
    <div style="margin-bottom: 15px;">
      <v-btn color="primary" @click="closeSettings" outlined small><v-icon>mdi-chevron-left</v-icon>Back to category list</v-btn>
    </div>
    <div>
      <label class="label" style="font-weight: bold; font-size: 20px"
      >General settings</label
      >
      <div style="padding:0 15px;">
        <div style="display:flex;align-items:center;flex:1;margin-bottom:15px;">
          <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Latitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              v-model="mapData.coordinates.lat"
          ></v-text-field>
        </div>
        <div style="display:flex;align-items:center;flex:1;margin-bottom:15px;">
          <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Longitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              v-model="mapData.coordinates.lon"
          ></v-text-field>
        </div>
        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Zoom range <b>{{ mapData.zoom }} eq</b></label>
          <div class="slider" style="width:100%;">
            <v-slider
                :max="18"
                :min="0"
                v-model="mapData.zoom"
                hide-details
            ></v-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapSettings",
  props:['mapData','content'],
  methods:{
    closeSettings(){
      this.content.styleSettings = false;
    }
  }
}
</script>

<style scoped>

</style>
