<template>
<div>
  <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 15px;
      "
  >
    <v-btn
        class="mb-4"
        color="primary"
        depressed
        outlined
        small
        @click="createMapPointCategory"
    >
      + Create category
    </v-btn>
  </div>
  <div style="margin-bottom: 15px;">
    <v-data-table
        class="table"
        :headers="headers"
        :items="pointCategories"
        :items-per-page="-1"
        hide-default-footer
        mobile-breakpoint="0"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length">
        <tr v-for="item in items" :key="item.id">
          <td
              style="text-align: center; cursor: pointer" @click="openPointList(item)">
            <div style="width : 100px;
                                                  overflow:hidden;
                                                  display:inline-block;
                                                  text-overflow: ellipsis;
                                                  white-space: nowrap;">

              {{ item.name[landing.current_lang] }}

            </div>
          </td>
          <td
              style="text-align: center; cursor: pointer" @click="openPointList(item)"
          >
            {{ item.points.length }}
          </td>
          <td  style="text-align: center">
            <v-chip
                class="ma-2"
                :color="!item.hide ? 'green' : 'red'"
                text-color="white"
                style="cursor: pointer"
                @click="changeActiveCategory(item)"
            >
              {{ !item.hide ? "Active" : "Inactive" }}
            </v-chip>
          </td>
          <td style="text-align: center;position: sticky;right: 0;background: #fff;">
            <v-menu offset-y open-on-hover bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    style="cursor: pointer" link @click="openEditModal(item)"
                >
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openDeleteCategoryPopup(item)">
                  <v-list-item-title
                      style="cursor: pointer; color: red"
                  >Delete</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link @click="openPointList(item)">
                  <v-list-item-title
                      style="cursor: pointer"
                  >
                    List points
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        </tbody>
        <tbody v-if="items.length === 0">
        <tr>
          <td class="d-block d-sm-table-cell"
              colspan="8"
              style="text-align: center; padding: 0 15px; font-weight: normal"
          >
            You don't have point categories yet, it's time to create them
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
  <CategoryModal @closeModal="showModal = false" :category="selectedCategory" :type="type" :showModal="showModal"/>
  <PopupDelete ref="PopupDelete" :title='`Are you sure about delete ${selectedCategory?selectedCategory.name[landing.current_lang]:""}?`' @confirm="deleteCategoryConfirm"/>

</div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CategoryModal from "@/components/Constructor/Menu/MapPoints/CategoryModal";
import PopupDelete from "@/components/Popup/PopupDelete";

export default {
  name: "CategoryList",
  components: {PopupDelete, CategoryModal},
  props:['selectedMenuID','item'],
  computed:{
    ...mapState("points", ["mapDataList"]),
    ...mapState(['landing']),
    pointCategories(){
      if(this.mapDataList.length){
        return this.mapDataList[0].point_categories
      }else{
        return []
      }
    },
    mapData(){
      if(this.mapDataList.length){
        let id;
        if(this.item.type === 10){
          id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
          id = this.item.id;
        }
        const candidate = this.mapDataList.find(x => x.menu_id === id);
        if(candidate){
          return candidate;
        }else{
          return null
        }
      }else{
        return null
      }
    }
  },
  data(){
    return{
      showModal:false,
      type:"Create",
      selectedCategory:null,
      headers:[
        { text: "Name", value: "name", align: "center", sortable: true },
        { text: "Points count", value: "points", align: "center", sortable: true },
        { text: "Active", value: "hide", align: "center",sortable: false },
        { text: "Options", value: "hotel_id", align: "center",sortable: false },
      ],
      defaultCategory:{
        hotel_id:null,
        map_id: null,
        menu_id: null,
        hide:0,
        name:{
          en:"default category name"
        }
      }
    }
  },
  methods:{
    ...mapActions('points',['editPointCategory','deletePointCategory']),
    ...mapMutations('points',['setSelectedCategory']),
    openPointList(category){
      this.setSelectedCategory(category.name[this.landing.current_lang])
      this.$emit('setSelectedCategory',category);
      this.$emit('setLocalPage','point');
    },
    openDeleteCategoryPopup(category){
      this.selectedCategory = JSON.parse(JSON.stringify(category));
      this.$refs.PopupDelete.open();
    },
    async deleteCategoryConfirm(){
      await this.deletePointCategory(this.selectedCategory);
    },
    async changeActiveCategory(category){
      if(category.hide){
        category.hide = 0;
      }else{
        category.hide = 1;
      }
      await this.editPointCategory(category);
    },
    openEditModal(category){
      this.type = 'Edit';
      this.selectedCategory = JSON.parse(JSON.stringify(category));
      this.showModal = true;
    },
    createMapPointCategory(){
      let category = JSON.parse(JSON.stringify(this.defaultCategory));
      let id;
      if(this.item.type === 10){
        id = +(this.selectedMenuID+""+this.item.id+'000');
      }else{
        id = this.item.id;
      }
      category.hotel_id = +this.$route.params.id;
      category.map_id = this.mapData.id;
      category.menu_id = id;
      for(let i  = 0;i<this.landing.advanced.selected_langs.length;i++){
        if(!category.name[this.landing.advanced.selected_langs[i]]){
          category.name[this.landing.advanced.selected_langs[i]] = 'default category name'
        }
      }
      this.selectedCategory = JSON.parse(JSON.stringify(category));
      this.showModal = true;
    }
  }
}
</script>

<style lang="scss">
.v-data-table-header tr th{
  white-space: nowrap;
  background: #fff;
}
.v-data-table-header tr th:last-child{
  position: sticky;
  right: 0;
}
</style>
