<template>
<div>
  <div v-if="localPage === 'list'&&!content.styleSettings" style="margin-bottom: 15px;display:flex;justify-content: space-between;align-items: center;">
    <v-btn color="primary" v-if="content.show_constructor_categories" @click="backToCategoryList" outlined small><v-icon>mdi-chevron-left</v-icon>Back to category list</v-btn>
    <div v-if="!content.show_constructor_categories"></div>
    <v-btn
        class="mb-4"
        color="primary"
        depressed
        outlined
        small
        @click="openCreatePoint"
    >
      + Create point
    </v-btn>
  </div>
  <div v-if="localPage === 'list'&&!content.styleSettings&&content.show_constructor_categories" style="margin-bottom: 15px;display:flex;justify-content: space-between;align-items: center;">
    <label class="label">Category: {{selectedCategory.name[landing.current_lang]}}</label>
  </div>
  <div v-if="localPage === 'list'&&!content.styleSettings&&content.show_constructor_categories">
    <v-data-table
        class="table"
        :headers="headers"
        :items="selectedCategory?selectedCategory.points:[]"
        :items-per-page="-1"
        hide-default-footer
        :disable-sort="true"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length">
        <tr v-for="item in items" :key="item.id">
          <td class="d-block d-sm-table-cell"
              style="text-align: center"
          >
            <img :src="item.main_image?item.main_image:require('@/assets/none.png')" style="width:40px;height:30px;" alt="">
          </td>
          <td class="d-block d-sm-table-cell" style="text-align: center">
            {{ item.name[landing.current_lang] }}
          </td>
          <td class="d-block d-sm-table-cell" style="text-align: center">
            <v-chip
                class="ma-2"
                :color="!item.hide ? 'green' : 'red'"
                text-color="white"
                style="cursor: pointer"
                @click="toggleActive(item)"
            >
              {{ !item.hide ? "Active" : "Inactive" }}
            </v-chip>
          </td>
          <td class="d-block d-sm-table-cell" style="text-align: center">
            <v-menu offset-y open-on-hover bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="openDetail(item)"
                    style="cursor: pointer" link
                >
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="openDeletePointPopup(item)">
                  <v-list-item-title
                      style="cursor: pointer; color: red"
                  >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        </tbody>
        <tbody v-if="items.length === 0">
        <tr>
          <td class="d-block d-sm-table-cell"
              colspan="8"
              style="text-align: center; padding: 0 15px; font-weight: normal"
          >
            You don't have points yet, it's time to create them
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
  <div v-if="localPage === 'list'&&!content.styleSettings&&!content.show_constructor_categories">
    <div style="display:flex;flex-direction:column">
      <div v-for="(point,index) in points" :style="`border-bottom:${index!==points.length-1?'1px solid #ddd;':'none'};`" :key="point.id" style="display: flex;justify-content: space-between;padding:15px 0;">
        <div style="display: flex;">
          <div style="padding:0 15px;">
            <v-switch v-model="point.hide" :value="!point.hide" color="rgb(106, 182, 74)" small hide-details style="padding: 0;margin: 0" />
          </div>
          <div style="display:flex;flex-direction:column">
            <p style="font-weight:bold;">{{point.name[landing.current_lang]}}</p>
            <div>
              <v-btn
                  small
                  outlined
                  color="primary"
                  style="cursor: pointer;"
                  @click="openDetail(point)">
                Edit
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <v-btn color="primary"  text dark small
                 @click="openDeletePointPopup(item)" outlined>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <div v-if="localPage === 'detail'&&!content.styleSettings">
    <PointDetail :content="content" @setLocalPage="setLocalPage" :point="selectedPoint"></PointDetail>
  </div>
  <div v-if="content.styleSettings">
    <StylesSettings @setLocalPage="setLocalPage" :content="content" />
  </div>
  <PopupDelete ref="PopupDelete" :title='`Are you sure about delete ${selectedPoint?selectedPoint.name[landing.current_lang]:""}?`' @confirm="deletePointConfirm"/>
</div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import PointDetail from "@/components/Constructor/Menu/MapPoints/PointDetail"
import PopupDelete from "@/components/Popup/PopupDelete"
import StylesSettings from "@/components/Constructor/Menu/MapPoints/StylesSettings"
export default {
  name: "PointList",
  components:{
    PointDetail,
    PopupDelete,
    StylesSettings
  },
  data(){
    return{
      headers:[
        { text: "Image", align: "center" },
        { text: "Name", value: "name", align: "center", sortable: true },
        { text: "Active",  align: "center" },
        { text: "Options",  align: "center" },
      ],
      defaultPoint:{
        hotel_id: null,
        map_id: null,
        menu_id: null,
        point_category_id:null,
        name: {
          en: "Default name",
        },
        description: {
          en: "Default description",
        },
        category: {
          en: "Default description",
        },
        icon: "restaurant",
        main_image: "",
        images: [],
        hide:0,
        video_url: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
        object_url: "",
      },
      selectedPoint:null,
      localPage:'list',
    }
  },
  props:[
      'selectedCategory',
      'selectedMenuID',
      'item',
      'content'
  ],
  computed:{
    ...mapState(['landing']),
    ...mapState("points", ["mapDataList"]),
    points(){
      return this.mapDataList.reduce((acc,item) => {
        if(item.point_categories.length){
          for(let i = 0;i<item.point_categories.length;i++){
            if(item.point_categories[i].points.length){
              acc = [...acc,...item.point_categories[i].points];
            }
          }
        }
        if(item.points.length){
          acc = [...acc,...item.points];
        }
        return acc;
      },[])
    },
  },
  methods:{
    ...mapActions('points',['editPoint','addPoint','deletePoint']),
    ...mapMutations('points',['setSelectedCategory']),
    backToCategoryList(){
      this.setSelectedCategory('All');
      this.$emit('setLocalPage','category')
    },
    openDeletePointPopup(point){
      this.selectedPoint = point;
      this.$refs.PopupDelete.open();
    },
    async deletePointConfirm(){
      await this.deletePoint(this.selectedPoint);
      this.selectedPoint = null;
    },
    async setLocalPage(page){
      if(page === 'list'&&this.selectedPoint){
        await this.editPoint(this.selectedPoint);
        this.$emit('triggered');
      }
      this.localPage = page;
    },
    openDetail(point){
      this.selectedPoint = point;
      this.localPage = 'detail';
      this.$emit('triggered');
    },
    async toggleActive(item){
      if(item.hide){
        item.hide = 0
      }else{
        item.hide = 1;
      }
      await this.editPoint(item);
    },
    async openCreatePoint(){
      this.selectedPoint = JSON.parse(JSON.stringify(this.defaultPoint));
      let id;
      if(this.item.type === 10){
        id = +(this.selectedMenuID+""+this.item.id+'000');
      }else{
        id = this.item.id;
      }
      this.selectedPoint.hotel_id = +this.$route.params.id;
      this.selectedPoint.map_id = this.selectedCategory?+this.selectedCategory.map_id:this.mapDataList[0].id;
      this.selectedPoint.menu_id = this.selectedCategory?+this.selectedCategory.menu_id:this.mapDataList[0].menu_id;
      this.selectedPoint.point_category_id = this.selectedCategory?+this.selectedCategory.id:null;
      await this.addPoint(this.selectedPoint);
      this.selectedPoint = this.selectedCategory?this.selectedCategory.points[this.selectedCategory.points.length-1]:this.mapDataList[0].points[this.mapDataList[0].points.length-1];
      this.localPage = 'detail';
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
