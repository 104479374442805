<template>
  <div class="points">
    <div  v-if="mapDataList.length">
      <div
          v-if="localPage === 'category'&&!content.styleSettings&&!triggered"
          style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
      >
        <label class="label" style="float: left"
        >Show categories</label
        >
        <v-switch color="#6ab64a"
                  v-model="content.show_constructor_categories"
        ></v-switch>
      </div>
      <div v-if="localPage === 'category'&&!content.styleSettings&&content.show_constructor_categories">
        <CategoryList @setLocalPage="setLocalPage" :item="item" :selectedMenuID="selectedMenuID" @setSelectedCategory="setSelectedCategory"/>
      </div>
      <div v-if="content.styleSettings&&localPage === 'category'">
        <MapSettings :mapData="mapData" :content="content" />
      </div>
      <div v-if="localPage === 'category'&&!content.styleSettings&&!content.show_constructor_categories">
        <PointList :content="content" :selectedMenuID="selectedMenuID" :item='item' @setLocalPage="setLocalPage" :selectedCategory="selectedCategory" @triggered="switchTrigger"/>
      </div>
      <div v-if="localPage === 'point'&&selectedCategory">
        <PointList :content="content" :selectedMenuID="selectedMenuID" :item='item' @setLocalPage="setLocalPage" :selectedCategory="selectedCategory"/>
      </div>
    </div>
    <PopupSuccess
      ref="PopupSuccess"
      title="Success"
      content="Saved successfully"/>
    <PopupConfirm
        ref="PopupConfirm"
        @confirm="confirmCopy"
        :title="
        `Are you sure about copy content from ${defaultLangName} to ${currentLangName}`
      "
    />
  </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";

import PopupSuccess from "@/components/Popup/PopupSuccess";
import PopupConfirm from "@/components/Popup/PopupConfirm"
    import CategoryList from "@/components/Constructor/Menu/MapPoints/CategoryList";
    import MapSettings from "@/components/Constructor/Menu/MapPoints/MapSettings";
    import PointList from "@/components/Constructor/Menu/MapPoints/PointList"
export default {
  name: "typeMapPoints",
  props: ["item", "content","selectedMenuID"],
  components: {
    PointList,
    MapSettings,
    CategoryList,
    PopupSuccess,
    PopupConfirm
  },
  data() {
    return {
      triggered:false,
      deleteCategoryObj:null,
      border_color:false,
      pointGroupIndex:null,
      pointIndex:null,
      shape_color: false,
      icon_color: false,
      settings: false,
      bg_color: false,
      deleteId: null,
      title_color: false,
      shape_icons: [
        "MAP_PIN",
        "SQUARE_PIN",
        "SHIELD",
        "ROUTE",
        "SQUARE",
        "SQUARE_ROUNDED",
      ],
      marker_icons: [
        "abseiling",
        "accounting",
        "airport",
        "amusement park",
        "aquarium",
        "archery",
        "art gallery",
        "assistive listening system",
        "atm",
        "audio description",
        "bakery",
        "bank",
        "bar",
        "baseball",
        "beauty salon",
        "bicycle store",
        "bicycling",
        "boat ramp",
        "boat tour",
        "boating",
        "book store",
        "bowling alley",
        "braille",
        "bus station",
        "cafe",
        "campground",
        "canoe",
        "car dealer",
        "car rental",
        "car repair",
        "car wash",
        "casino",
        "cemetery",
        "chairlift",
        "church",
        "circle",
        "city hall",
        "climbing",
        "closed captioning",
        "clothing store",
        "compass",
        "convenience store",
        "courthouse",
        "cross country skiing",
        "crosshairs",
        "dentist",
        "department store",
        "diving",
        "doctor",
        "electrician",
        "electronics store",
        "embassy",
        "expand",
        "female",
        "finance",
        "fire station",
        "fish cleaning",
        "fishing pier",
        "fishing",
        "florist",
        "food",
        "fullscreen",
        "funeral home",
        "furniture store",
        "gas station",
        "general contractor",
        "golf",
        "grocery or supermarket",
        "gym",
        "hair care",
        "hang gliding",
        "hardware store",
        "health",
        "hindu temple",
        "horse riding",
        "hospital",
        "ice fishing",
        "ice skating",
        "inline skating",
        "insurance agency.txt",
        "jet skiing",
        "jewelry store",
        "kayaking",
        "laundry",
        "lawyer",
        "library",
        "liquor store",
        "local government",
        "location arrow",
        "locksmith",
        "lodging",
        "low vision access",
        "male",
        "map pin",
        "marina",
        "mosque",
        "motobike trail",
        "movie rental",
        "movie theater",
        "moving company",
        "museum",
        "natural feature",
        "night club",
        "open captioning",
        "painter",
        "park",
        "parking",
        "pet store",
        "pharmacy",
        "physiotherapist",
        "place of worship",
        "playground",
        "plumber",
        "point of interest",
        "police",
        "political",
        "post box",
        "post office",
        "postal code prefix",
        "postal code",
        "rafting",
        "real estate agency.txt",
        "restaurant",
        "roofing contractor",
        "route pin",
        "route",
        "rv park",
        "sailing",
        "school",
        "scuba diving",
        "search",
        "shield",
        "shopping mall",
        "sign language",
        "skateboarding",
        "ski jumping",
        "skiing",
        "sledding",
        "snow shoeing",
        "snow",
        "snowboarding",
        "snowmobile",
        "spa",
        "square pin",
        "square rounded",
        "square",
        "stadium",
        "storage",
        "store",
        "subway station",
        "surfing",
        "swimming",
        "synagogue",
        "taxi stand",
        "tennis",
        "toilet",
        "trail walking",
        "train station",
        "transit station",
        "travel agency.txt",
        "unisex",
        "university",
        "veterinary care",
        "viewing",
        "volume control telephone",
        "walking",
        "waterskiing",
        "whale watching",
        "wheelchair",
        "wind surfing",
        "zoo",
        "zoom in alt",
        "zoom in",
        "zoom out alt",
        "zoom out",
      ],
      localPage:"category",
      selectedCategory:null
    };
  },
  computed: {
    ...mapState("points", ["mapDataList"]),
    ...mapState(["landing"]),
    currentLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
    mapData(){
      if(this.mapDataList){
          let id;
          if(this.item.type === 10){
              id = +(this.selectedMenuID+""+this.item.id+'000');
          }else{
              id = this.item.id;
          }
        const candidate = this.mapDataList.find(x => x.menu_id === id);
        if(candidate){
          return candidate;
        }else{
          return null
        }
      }else{
        return null
      }
    }
  },
  methods: {
      ...mapMutations("landing", ["setIsDirty", "setIsAbleToWatch"]),
    ...mapActions("points", ["getMapData", "addPoint", "deletePoint","editPointCategory","savePoint","addPointCategory","deletePointCategory","updateMapData"]),
    switchTrigger(){
      this.triggered = !this.triggered;
    },
    setSelectedCategory(category){
      this.selectedCategory = category;
    },
    setLocalPage(page){
      this.localPage = page;
    },
    copyLang(){
      this.$refs.PopupConfirm.open();
    },
    mountDropzone(){
        for (let i = 0; i < this.mapData.point_categories.length; i++) {
          for (
              let index = 0;
              index < this.mapData.point_categories[i].points.length;
              index++
          ) {
            let item = this.mapData.point_categories[i].points[index];
            if (item.main_image) {
              let mockFile = {
                accepted: true,
                cropped: true,
                kind: "image",
                ...item,
              };
              if(this.$refs[`mainDropzone_${i}_${index}`]){
                if (
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone &&
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone.files.length === 0
                ) {
                  this.$refs[`mainDropzone_${i}_${index}`][0].manuallyAddFile(
                    mockFile,
                    item.main_image
                  );
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[0].children[0].src = item.main_image;
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[0].style.borderRadius = 0;
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[1].style.background = "none";
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone.emit(
                    "complete",
                    mockFile
                  );
                }
              }
            }
          }
        }
    },
    confirmCopy() {
      let defaultContent = JSON.parse(
          JSON.stringify(this.item.content.en[10])
      );
      this.item.content[this.landing.current_lang][10].styles =
          defaultContent.styles;
      this.item.content[this.landing.current_lang][10].active_slider =
          defaultContent.active_slider;
      this.item.content[this.landing.current_lang][10].slider_menu =
          defaultContent.slider;
      for(let indexMap = 0;indexMap<this.mapDataList.length;indexMap++){
        for(let indexCategory = 0;indexCategory<this.mapDataList[indexMap].point_categories.length;indexCategory++){
          this.mapDataList[indexMap].point_categories[indexCategory].name[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].name.en.split('').join('');
          for(let indexPoint = 0;indexPoint<this.mapDataList[indexMap].point_categories[indexCategory].points.length;indexPoint++){
            this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].name[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].name.en.split('').join('')
            this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].description[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].description.en.split('').join('')
          }
        }
      }
      this.saveMap();
    },
    async saveMap(){
      await this.updateMapData(this.mapData);
      for(let i = 0;i<this.mapData.point_categories.length;i++){
        await this.editPointCategory(this.mapData.point_categories[i]);
        for(let index = 0;index<this.mapData.point_categories[i].points.length;index++){
          await this.savePoint(this.mapData.point_categories[i].points[index]);
        }
      }
      await this.$store.dispatch("landing/saveLanding", this.$route.params.id);
      this.$emit('save');
      this.setIsDirty(false);
      this.setIsAbleToWatch(true);
      this.$refs.PopupSuccess.open();
    },
    hidePoint(point) {
      if(point.hide){
        point.hide = 0;
      }else{
        point.hide = 1;
      }
    },
    deleteCategory(category){
      this.deleteCategoryObj = category;
      this.$refs.PopupDeletePointCategory.open()
    },
    async deleteCategoryConfirm(){
      await this.deletePointCategory(this.deleteCategoryObj);
      this.deleteCategoryObj = null;
    },
    showSettings() {
      this.settings = !this.settings;
    },
    async pointDelete() {
      await this.deletePoint(this.deleteId);
      this.deleteId = null;
    },
    deletePointView(point) {
      this.$refs.PopupDeletePoint.open();
      this.deleteId = point;
    },
    maxFileSexceeded(file,groupIndex, index) {
      this.$refs[`mainDropzone_${groupIndex}_${index}`][0].removeAllFiles();
      this.$refs[`mainDropzone_${groupIndex}_${index}`][0].addFile(file);
    },
    handleRemove(file,groupIndex, index) {
      this.mapData.point_categories[groupIndex].points[index].main_image = "";
    },
    handleSuccess(file,groupIndex, index) {
      this.mapData.point_categories[groupIndex].points[index].main_image = file.xhr.response;
    },
    customEvent(_file, xhr, formData,groupIndex,index) {
      this.pointGroupIndex = groupIndex;
      this.pointIndex = index;
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    changeZoomShow(val) {
      if (val) {
        this.mapData.show_zoom = 1;
      } else {
        this.mapData.show_zoom = 0;
      }
    },
    changeShowFullscreen(val) {
      if (val) {
        this.mapData.show_fullscreen = 1;
      } else {
        this.mapData.show_fullscreen = 0;
      }
    },
    changeMapType(val) {
      if (val) {
        this.mapData.map_type = 1;
      } else {
        this.mapData.map_type = 0;
      }
    },
    async addCategory(){
        let id;
        if(this.item.type === 10){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      let category = {
        hotel_id:+this.$route.params.id,
        map_id: this.mapData.id,
        menu_id: id,
        hide:0,
        name:{
          en:"default category name"
        }
      }
      await this.addPointCategory(category);
    },
    async addMapPoint(group) {
        let id;
        if(this.item.type === 10){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      let defaultPoint = {
        hotel_id: +this.$route.params.id,
        map_id: this.mapData.id,
        menu_id: id,
        point_category_id:group,
        name: {
          en: "Default name",
        },
        description: {
          en: "Default description",
        },
        category: {
          en: "Default description",
        },
        icon: "restaurant",
        main_image: "",
        images: [],
        hide:0,
        video_url: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
        object_url: "",
      };
      await this.addPoint(defaultPoint);
    },
  },
  async mounted() {
      let id;
      if(this.item.type === 10){
          id = +(this.selectedMenuID+""+this.item.id+'000');
      }else{
          id = this.item.id;
      }
    const payload = {
      hotel_id: this.$route.params.id,
      menu_id: id,
    };
    await this.getMapData(payload);
  },
};
</script>

<style lang="scss" scoped>
.points_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.margin {
  margin-bottom: 15px;
}
.coordinates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.points_buttons {
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: center;
}
.point_block {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  padding: 15px;
}
.colors {
  display: flex;
  align-items: center;
}
.points_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
