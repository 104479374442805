<template>
<div>
  <div>
    <v-btn color="primary" @click="$emit('setLocalPage','list')" outlined small><v-icon>mdi-chevron-left</v-icon>Back to points list</v-btn>
  </div>
  <div style="padding-top: 15px;">
    <div>
      <div>
        <label class="label" style="font-weight:bold;">Block Settings:</label>
      </div>
      <div style="padding:15px;">
        <div class="margin" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="display: block;margin-bottom: 0;width: 150px;margin-right: 8px;"
          >Height
            <b>{{ content.styles.block_height }}</b>
            px</label
          >
          <div class="slider" style="width:100%">
            <v-slider
                hide-details
                :min="100"
                :max="600"
                v-model="content.styles.block_height"
            ></v-slider>
          </div>
        </div>
        <div class="margin" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="display: block;margin-bottom: 0;width: 150px;margin-right: 8px;"
          >Width
            <b>{{ content.styles.block_width }}</b>
            px</label
          >
          <div class="slider" style="width:100%">
            <v-slider
                hide-details
                :min="90"
                :max="300"
                v-model="content.styles.block_width"
            ></v-slider>
          </div>
        </div>
        <div class="margin" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="display: block;margin-bottom: 0;width: 150px;margin-right: 8px;"
          >Radius
            <b>{{ content.styles.radius }}</b>
            px</label
          >
          <div class="slider" style="width:100%">
            <v-slider
                hide-details
                :min="0"
                :max="50"
                v-model="content.styles.radius"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <label class="label" style="font-weight:bold;">Block Settings:</label>
      </div>
      <div style="padding: 15px;">
        <div class="margin" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="display: block;margin-bottom: 0;width: 150px;margin-right: 8px;"
          >Height
            <b>{{ content.styles.image_height }}</b>
            px</label
          >
          <div class="slider" style="width: 100%">
            <v-slider
                hide-details
                :min="90"
                :max="300"
                v-model="content.styles.image_height"
            ></v-slider>
          </div>
        </div>
        <div class="margin" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="display: block;margin-bottom: 0;width: 150px;margin-right: 8px;"
          >Width
            <b>{{ content.styles.image_width }}</b>
            %</label
          >
          <div class="slider" style="width: 100%">
            <v-slider
                hide-details
                :min="10"
                :max="100"
                v-model="content.styles.image_width"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>
    <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
      <label
          class="label"
          style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
      >Background color</label
      >
      <v-menu
          v-model="bg_color"
          top
          nudge-bottom="98"
          nudge-left="16"
          :close-on-content-click="false"
          z-index="21"
      >
        <template v-slot:activator="{ on }">
          <div
              :style="`background-color:${content.styles.bg_color}`"
              class="color"
              v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="content.styles.bg_color" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div>
      <div>
        <label class="label" style="font-weight:bold;">Color Settings:</label>
      </div>
      <div style="padding: 15px">
        <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label
              class="label"
              style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
          >Title color</label
          >
          <v-menu
              v-model="title_color"
              top
              nudge-bottom="98"
              nudge-left="16"
              :close-on-content-click="false"
              z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                  :style="`background-color:${content.styles.title_color}`"
                  class="color"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="content.styles.title_color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label
              class="label"
              style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
          >Shape color</label
          >
          <v-menu
              v-model="shape_color"
              top
              nudge-bottom="98"
              nudge-left="16"
              :close-on-content-click="false"
              z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                  :style="`background-color:${content.styles.marker_shape_color}`"
                  class="color"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                    v-model="content.styles.marker_shape_color"
                    flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label
              class="label"
              style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
          >Icon color</label
          >
          <v-menu
              v-model="icon_color"
              top
              nudge-bottom="98"
              nudge-left="16"
              :close-on-content-click="false"
              z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                  :style="`background-color:${content.styles.marker_icon_color}`"
                  class="color"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                    v-model="content.styles.marker_icon_color"
                    flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label
              class="label"
              style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
          >Border color</label
          >
          <v-menu
              v-model="border_color"
              top
              nudge-bottom="98"
              nudge-left="16"
              :close-on-content-click="false"
              z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                  :style="`background-color:${content.styles.border_color}`"
                  class="color"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="content.styles.border_color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label
              class="label"
              style="margin-bottom: 0; margin-right: 15px; min-width: 150px"
          >
            Shape</label>

          <v-autocomplete
              dense
              flat
              outlined
              solo
              class="input input_dense"
              :items="shape_icons"
              hide-details
              style="margin-bottom: 0; margin-right: 15px; max-width: 300px"
              v-model="content.styles.marker_shape"
          >
            <template v-slot:selection="data">
              <span
                  :class="`map-icon map-icon-${data.item
                  .toLowerCase()
                  .replace('_', '-')}`"
                  style="font-size: 20px; margin-right: 10px"
                  :style="`color:${content.styles.marker_shape_color};`"
              ></span>
              {{ data.item.toLowerCase().replace("_", " ") }}
            </template>
            <template v-slot:item="data">
              <span
                  :class="`map-icon map-icon-${data.item
                  .toLowerCase()
                  .replace('_', '-')}`"
                  style="font-size: 20px; margin-right: 10px"
                  :style="`color:${content.styles.marker_shape_color};`"
              ></span>
              {{ data.item.toLowerCase().replace("_", " ") }}
            </template>
          </v-autocomplete>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "StylesSettings",
  props:['content'],
  data(){
    return{
      bg_color:false,
      title_color:false,
      shape_color:false,
      icon_color:false,
      border_color:false,
      shape_icons: [
        "MAP_PIN",
        "SQUARE_PIN",
        "SHIELD",
        "ROUTE",
        "SQUARE",
        "SQUARE_ROUNDED",
      ],
    }
  }
}
</script>

<style scoped>

</style>
