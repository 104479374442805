import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"center","margin-bottom":"15px"}},[_c(VBtn,{staticClass:"mb-4",attrs:{"color":"primary","depressed":"","outlined":"","small":""},on:{"click":_vm.createMapPointCategory}},[_vm._v(" + Create category ")])],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.pointCategories,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){return _vm.openPointList(item)}}},[_c('div',{staticStyle:{"width":"100px","overflow":"hidden","display":"inline-block","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.name[_vm.landing.current_lang])+" ")])]),_c('td',{staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){return _vm.openPointList(item)}}},[_vm._v(" "+_vm._s(item.points.length)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_c(VChip,{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":!item.hide ? 'green' : 'red',"text-color":"white"},on:{"click":function($event){return _vm.changeActiveCategory(item)}}},[_vm._v(" "+_vm._s(!item.hide ? "Active" : "Inactive")+" ")])],1),_c('td',{staticStyle:{"text-align":"center","position":"sticky","right":"0","background":"#fff"}},[_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{staticStyle:{"cursor":"pointer"},attrs:{"link":""},on:{"click":function($event){return _vm.openEditModal(item)}}},[_c(VListItemTitle,[_vm._v("Edit")])],1),_c(VListItem,{on:{"click":function($event){return _vm.openDeleteCategoryPopup(item)}}},[_c(VListItemTitle,{staticStyle:{"cursor":"pointer","color":"red"}},[_vm._v("Delete")])],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.openPointList(item)}}},[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"}},[_vm._v(" List points ")])],1)],1)],1)],1)])}),0):_vm._e(),(items.length === 0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"8"}},[_vm._v(" You don't have point categories yet, it's time to create them ")])])]):_vm._e()]}}])})],1),_c('CategoryModal',{attrs:{"category":_vm.selectedCategory,"type":_vm.type,"showModal":_vm.showModal},on:{"closeModal":function($event){_vm.showModal = false}}}),_c('PopupDelete',{ref:"PopupDelete",attrs:{"title":("Are you sure about delete " + (_vm.selectedCategory?_vm.selectedCategory.name[_vm.landing.current_lang]:"") + "?")},on:{"confirm":_vm.deleteCategoryConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }