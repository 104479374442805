<template>
  <div>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="showModal" max-width="500px" v-if="category" @click:outside="closeModal">
    <v-card>
      <v-icon
          style="top: 10px; right: 10px; position:absolute;"
          class="close__modal"
          @click="closeModal"
      >mdi-close</v-icon
      >
      <v-card-title>
        <span style="text-transform:capitalize;" class="text-h5">{{ type }} point category</span>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="selected_lang">
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @click="changeLang(selected_lang)">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang" style="padding-top: 15px;">
              <div style="display: flex;align-items: center;">
                <label class="label" style="display: block;margin-right:15px;margin-bottom:0;">Name</label>
                <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="category.name[lang]"
                ></v-text-field>
              </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions style="padding: 20px 24px">
        <v-spacer></v-spacer>
        <v-btn
            class="object__submit btn btn_primary"
            color="primary"
            outlined
            small
            @click="saveCategory"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
  </div>
</template>

<script>
import PopupSuccess from "@/components/Popup/PopupSuccess";
import {mapState,mapActions} from "vuex";
export default {
  components:{
    PopupSuccess
  },
  computed:{
    ...mapState(['landing']),
    ...mapState('landing',['selected_langs']),
  },
  name: "CategoryModal",
  data(){
    return{
      successMessage:"",
      selected_lang:0,
    }
  },
  props:{
    category:{
      type:Object
    },
    showModal:{
      type:Boolean,
      default:false,
    },
    type:{
      type:String,
      default:"Create"
    }
  },
  methods:{
    ...mapActions("points", ["editPointCategory","addPointCategory"]),
    changeLang(lang){
      this.landing.current_lang = lang;
    },
    closeModal(){
      this.$emit('closeModal');
    },
    async saveCategory(){
      if(this.type === 'Create'){
        await this.addPointCategory(this.category);
        this.successMessage = 'New point category created successfully!';
        this.$emit('closeModal');
        this.$refs.PopupSuccess.open();
      }else{
        await this.editPointCategory(this.category);
        this.successMessage = `Point category ${this.category.name[this.landing.current_lang]} edited successfully!`;
        this.$emit('closeModal');
        this.$refs.PopupSuccess.open();
      }
    }
  }
}
</script>

<style scoped>

</style>
