import { render, staticRenderFns } from "./typeMapPoints.vue?vue&type=template&id=bfe363fa&scoped=true&"
import script from "./typeMapPoints.vue?vue&type=script&lang=js&"
export * from "./typeMapPoints.vue?vue&type=script&lang=js&"
import style0 from "./typeMapPoints.vue?vue&type=style&index=0&id=bfe363fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe363fa",
  null
  
)

export default component.exports